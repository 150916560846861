@import '../../../scss/theme-bootstrap';

.my-account-lists-and-favorites-v1 {
  .product {
    *zoom: 1;
    @include swap_direction(margin, 0 0 15px 0);
  }
  .product:before,
  .product:after {
    content: ' ';
    display: table;
  }
  .product:after {
    clear: both;
  }
  .product .product-thumb {
    float: #{$ldirection};
    margin-#{$rdirection}: 5px;
    padding-top: 2px;
  }
  .product .meta {
    overflow: hidden;
    font-size: 13px;
  }
  .product .meta h4.product-title {
    line-height: 15px;
    margin-bottom: 0;
    color: black;
    text-transform: none;
  }
  .product .meta h4.product-title a {
    color: #1f1f1f;
  }
  .product .meta h4.product-title a:hover {
    color: $color-cl-green-2;
  }
  .product .meta span {
    display: block;
    margin-bottom: 2px;
  }
  .product .meta .button {
    margin: 5px 0;
  }
  .product-col3 {
    max-height: 228px;
    overflow: hidden;
    *zoom: 1;
    @include swap_direction(margin, 0 0 17px 0);
    padding: 0;
  }
  .product-col3:before,
  .product-col3:after {
    content: ' ';
    display: table;
  }
  .product-col3:after {
    clear: both;
  }
  .product-col3 > li {
    display: block;
    float: #{$ldirection};
    min-height: 228px;
    width: 33%;
    @include swap_direction(padding, 0 5px 0 0);
  }
  .product-col3 > li:last-child {
    padding: 0;
  }
  .my-account-touts {
    overflow: hidden;
  }
  .my-account-touts .my-account-tout {
    float: #{$ldirection};
    margin: 35px 0;
    min-height: 120px;
    @include swap_direction(padding, 0 20px 0 0);
    width: 50%;
  }
  .my-account-touts .my-account-tout.tout-last {
    border-#{$ldirection}: 1px solid #dadada;
    @include swap_direction(padding, 0 0 0 20px);
  }
  .no-favorites {
    padding: 0;
    .no-favorites-text {
      @include swap_direction(padding, 20px 0 0 0);
      p {
        font-size: 22px;
        font-family: $base-font-family;
        margin: 0;
      }
      .button {
        @include swap_direction(margin, 15px 0 0 0);
      }
    }
    .no-favorites-image {
      @include swap_direction(padding, 15px 0 0 0);
      text-align: center;
      img {
        display: inline;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        width: auto;
      }
    }
  }
}
